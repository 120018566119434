<template>
  <div class="bridge" v-if="transactions.length">
    <div class="bridge-table main-block">
      <span class="bridge-table--title">{{ $t('bridge.txHistory') }}</span>
      <div class="bridge-table__header">
        <div class="bridge-table__header--label">
          <span>{{ $t('bridge.address') }}</span>
        </div>
        <div class="bridge-table__header--label">
          <span>{{ $t('bridge.sendHistory') }}</span>
        </div>
        <div class="bridge-table__header--label">
          <span>{{ $t('bridge.receiveHistory') }}</span>
        </div>
        <div class="bridge-table__header--label">
          <span>{{ $t('bridge.datetime') }}</span>
        </div>
      </div>

      <div
        class="bridge-table__body"
        :key="index"
        v-for="(transaction, index) in transactions"
      >
        <div class="bridge-table__body--col">
          <span>{{shrinkString(transaction.in.address)}}</span>
        </div>
        <div class="bridge-table__body--col">
        <span>
          {{ $BN(transaction.amount.number).plus($BN(transaction.amount.fee)) | amount(4) }}
          {{transaction.in.token}} {{transaction.in.network}}
        </span>
        </div>
        <div class="bridge-table__body--col">
        <span>
          {{ $BN(transaction.amount.number) | amount(4) }}
          {{transaction.out.token}} {{transaction.out.network}}
        </span>
        </div>
        <div class="bridge-table__body--col">
          <span>{{ transaction.time | datetime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { shrinkString } from '../../helpers/filters';

export default {
  name: 'historyTable',

  props: ['network', 'apiUrl'],

  data() {
    return {
      transactions: [],
    };
  },

  methods: {
    shrinkString,
  },

  mounted() {
    return axios.get(
      `${this.apiUrl}/payments/${this.$web3.currentProvider.selectedAddress}`,
    ).then(({ data }) => {
      this.transactions = data?.payments;
    });
  },
};
</script>

<style scoped lang="scss">
  .bridge {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    &-table {
      display: flex;
      flex-direction: column;
      width: calc(50% - 2rem);

      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      padding: 1.8rem 2.4rem;

      &--title {
        font-weight: 600;
        font-size: 2.9rem;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        margin-top: 3.2rem;
        margin-bottom: 2.4rem;

        &--label {
          font-size: 1.8rem;
          font-weight: 600;
          display: flex;
          justify-content: flex-end;
          flex: 1;

          &:first-child {
            flex: 1.2;
            justify-content: flex-start;
          }
        }
      }

      &__body {
        display: flex;
        flex: 1;
        justify-content: space-between;
        border-bottom: 1px solid #edf1f2;
        align-items: center;
        padding: 1.6rem 0;

        &:last-child {
          border-bottom: none;
        }

        &--col {
          display: flex;
          flex: 1;
          align-items: center;
          font-size: 1.8rem;
          font-weight: 500;
          justify-content: flex-end;

          &:first-child {
            flex: 1.2;
            justify-content: flex-start;
          }

          img {
            width: 4.4rem;
            height: 4.4rem;
            margin-right: 1rem;
            border-radius: 50%;
            background: #edf1f2;
          }
        }
      }
    }
  }
</style>
