import Vue from 'vue';

const sendTx = (amount, { to, token }) => {
  const decimals = 18;
  const amountBn = new Vue.$BN(amount.toFixed(18));
  const contract = Vue.$contracts.getContract(token);
  const from = Vue.$web3.currentProvider.selectedAddress;
  const value = amountBn.times(new Vue.$BN(10).pow(decimals)).toString();
  return contract.methods.transfer(to, value).send({ from });
};
export default sendTx;
