<template>
  <div class="exchange" v-if="fees">
    <div class="exchange__header">
      <span>{{ $t('bridge.swap') }} {{ chainForm }}</span>
      <img src="@/assets/img/exchange-icon.svg"/>
      <span>{{ chainTo }}</span>
    </div>
    <div class="exchange__body main-block">
      <div class="exchange__body--input">
        <InputComponent @click="inputMax" :max="true" @change="validateAmount" v-model="amount">
          <template v-slot:label>
            {{ $t('bridge.send') }}
          </template>
          <template v-slot:input>
            <span class="input-token-name">{{ token }}</span>
          </template>
        </InputComponent>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <Button>
                {{ token }} {{ chainForm }} <img class="caret" src="@/assets/img/caret.svg"/>
              </Button>
            </div>
          </template>
          <v-list>
            <v-list-item @click="token = item" v-for="(item, index) in items" :key="index">
              <span :class="{ active: token === item }">
                {{ item }}
              </span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="exchange__body--input">
        <InputComponent :value="amountWithFee">
          <template v-slot:label>
            {{ $t('bridge.receive') }}
          </template>
        </InputComponent>
        <Button :disabled="true">{{ token }} {{ chainTo }}</Button>
      </div>
      <div class="exchange__footer">
        <span v-html="$t('bridge.commission', { token, fee })"> </span>
        <br/>
        <span><strong>{{ $t('bridge.alert') }}</strong></span>
        <Button :disabled="loading" @click="send">
          {{ $t('bridge.swap') }}
        </Button>
      </div>
    </div>
    <HistoryTable :network="chainForm" :api-url="apiUrl"/>
  </div>
  <div v-else-if="isError" class="bridge-error">
    <h1>{{$t('out-of-service')}}</h1>
  </div>
</template>

<script>
import sendTx from '@/utils/sendTx';
import { mapState } from 'vuex';
import axios from 'axios';
import { addNotification, clearNotification } from '@/utils/notification';
import Button from '../components/Button';
import InputComponent from '../components/InputComponent';
import HistoryTable from './bridge/historyTable';

export default {
  name: 'bridge',
  components: { InputComponent, Button, HistoryTable },

  data() {
    return {
      amount: 0,
      token: 'milk2',
      loading: false,
      fees: null,
      items: ['milk2', 'shake'],
      isError: false,
    };
  },

  mounted() {
    axios({
      method: 'get',
      url: `${this.apiUrl}/fees`,
      timeout: 1000 * 5,
    }).then(({ data }) => {
      const result = {};
      if (!data) {
        this.isError = true;

        return null;
      }

      data.fees.forEach((fee) => {
        if (!result[fee.network]) result[fee.network] = {};
        result[fee.network][fee.token] = fee.fee;
      });

      this.fees = result;

      return null;
    }).catch(() => {
      this.isError = true;
    });
  },

  computed: {
    ...mapState({
      milk2Balance: (state) => state.Milk.milkBalance || 0,
      shakeBalance: (state) => state.Shake.balance || 0,
      chainID: (state) => Number(state.User.chainId),
    }),
    chainForm() {
      return this.isBep(this.chainID) ? 'bep20' : 'erc20';
    },
    chainTo() {
      return this.isBep(this.chainID) ? 'erc20' : 'bep20';
    },
    isTestNet() {
      return this.chainID === 97 || this.chainID === 4;
    },
    fee() {
      return this.fees[this.chainTo][this.token];
    },
    apiUrl() {
      return process.env[`VUE_APP_API_BRIDGE_${this.isTestNet ? 'TESTNET' : 'MAINET'}`];
    },
    amountWithFee() {
      const amount = this.amount - this.fee;
      return amount > 0 ? amount : 0;
    },
  },

  methods: {
    isBep(id) {
      return id === 56 || id === 97;
    },
    inputMax() {
      this.amount = this.token === 'milk2'
        ? Math.floor(Number(this[`${this.token}Balance`]))
        : Number(this[`${this.token}Balance`]);
    },
    validateAmount() {
      const nAmount = Number(this.amount);
      const maxBalance = Number(this[`${this.token}Balance`]);
      this.amount = this.token === 'milk2' ? nAmount.toFixed() : nAmount;
      if (nAmount > maxBalance) {
        this.amount = this.token === 'milk2' ? maxBalance.toFixed() : maxBalance;
      } else if (nAmount < this.fees) {
        this.amount = this.fees;
      }
    },
    send() {
      this.loading = true;
      let tmpHash = null;
      axios
        .post(`${this.apiUrl}/swap`, {
          address: this.$web3.currentProvider.selectedAddress,
          network: this.chainTo,
          token: this.token,
          amount: Number(this.amount),
        })
        .then(async ({ data }) => {
          try {
            await sendTx(Number(this.amount), {
              to: data.address.address,
              token: this.token,
            }).on('transactionHash', (hash) => {
              tmpHash = hash;
              addNotification({
                type: 'info',
                title: 'Pending approve:',
                data: { hash: tmpHash },
              });
              this.loading = false;
            });
            clearNotification('info');
            addNotification({
              type: 'success',
              title: 'Successfully approved!',
              data: { hash: tmpHash },
              config: { duration: 20000 },
            });
            this.loading = false;
          } catch (e) {
            console.error(e);
            clearNotification('info');
            addNotification({
              type: 'warn',
              title: 'Approve failed!',
              config: { duration: 5000 },
            });
          } finally {
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.exchange {
  &__header {
    font-size: 4.4rem;
    font-weight: bold;
    justify-content: center;
    display: flex;
    margin-bottom: 4.5rem;
    margin-top: 5rem;
    align-items: center;

    img {
      margin: 0 1.5rem;
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  &__body {
    max-width: 89rem;
    width: 100%;
    margin: 0 auto;
    padding: 4rem 5rem;

    .caret {
      width: 1rem;
      height: 0.8rem;
    }

    &--input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        margin-bottom: 5rem;
      }

      .input-token-name {
        display: none;
        @media screen and (max-width: $breakpoint-sm) {
          display: inline;
        }
      }

      button {
        height: 7rem;
        border-radius: 3.5rem;
        margin-left: 2rem;
        font-size: 1.6rem;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.8rem;
    max-width: 53rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    span {
      color: $grey-font-color;
    }

    strong {
      color: $main-font-color;
    }

    button {
      max-width: 35.5rem;
      width: 100%;
      margin-top: 3rem;
    }
  }
}
</style>
<style lang="scss">
.exchange {
  font-weight: 500;
  margin-bottom: 10rem;

  .input-container .label {
    text-align: left !important;
    font-weight: 600;
  }

  .btn--round {
    font-weight: 600 !important;
  }

  .input-container {
    flex: 1;
  }
}
</style>
<style lang="scss">
.v-menu__content {
  box-shadow: 0 3px 3px -3px rgb(0 0 0 / 10%), 0 5px 5px 1px rgb(0 0 0 / 5%),
  0 3px 7px 2px rgb(0 0 0 / 5%);

  .v-list-item {
    cursor: pointer;

    span {
      font-size: 1.8rem;
      font-weight: 500;
      color: $main-font-color;

      &.active {
        font-weight: 700;
      }
    }

    &:hover {
      background: #f0f0f0;
    }
  }
}

.bridge-error {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
</style>
